import { FileUpload, Link, Loading, Retry, sanitizeCompanyAddress } from '@shared-ui/components'
import { useQuery, useRequest } from '@shared-ui/hooks'
import type { CompanyId } from '@shared/types/brands'
import type { CompanyInfo } from '@shared/types/company'
import { CompanyType } from '@shared/types/tickets'
import { Button, Dropdown, Loader, Text } from '@ubnt/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'rma-frontend-shared/utils/debounce'
import { useSubmitRma } from '../../context/SubmitRma'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { SellerInfo, SellerInfoUbiquiti } from '../seller-info'
import { StepHeading } from '../step-heading'
import { SubmitContainer } from '../submit-container'
import { UCareGradient } from '../u-care'
import { UCareBadge } from '../u-care-badge'
import {
  useSelectSeller_CompanyLookupLazyQuery as useCompanyLookupLazyQuery,
  useValidateFilesLazyQuery as useValidateFilesLazy,
} from './__generated__/SelectSeller'

type SelectSellerType = 'ui-care' | 'original' | 'unknown'

const CouldNotFindText = 'I cannot find my vendor'

export function SelectSeller() {
  const { device, seller, isOriginalSeller, isProofOfPurchaseNeeded } = useSubmitRmaStore()
  const { companyId, proofOfPurchaseFiles, setProofOfPurchaseFiles, setUserCompanyId } = useSubmitRma()
  const { data, loading, error } = useQuery('companySellers', { companyId })

  const isUnifiCare = device?.isUnifiCare || false
  const isUnifiCareDistributor = isUnifiCare && !device?.isSoldByUbiquiti
  const isUbiquiti = device?.isSoldByUbiquiti || false
  const canSelectSeller = !isUbiquiti

  useEffect(() => {
    setUserCompanyId(seller?.id)
  }, [seller, setUserCompanyId])

  const handleSellerNotFound = () => {
    SubmitRmaService.setView('addSeller')
  }

  if (!device?.id) {
    SubmitRmaService.back()
    return null
  }

  let heading = 'Who sold you this product?'
  if (isUnifiCare && !isUnifiCareDistributor) {
    heading = 'Attach Proof of Purchase'
  }

  let sellerType: SelectSellerType = 'unknown'
  if (isUnifiCare) {
    sellerType = 'ui-care'
  } else if (seller && isOriginalSeller) {
    sellerType = 'original'
  }

  return (
    <SubmitContainer>
      {isUnifiCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column relative p-16 mt-6 gap-24">
        <div className="flex column">
          <StepHeading heading={heading} />

          <Text size="body" className="mt-8">
            {
              {
                'ui-care': (
                  <>
                    {isUnifiCareDistributor && (
                      <>
                        Search for and select your seller below. If you can&apos;t find them, click the{' '}
                        <i>{CouldNotFindText}</i> link to enter their information.
                      </>
                    )}
                    {!isUnifiCareDistributor && (
                      <>
                        Please upload a receipt, invoice, or other relevant documentation that verifies your purchase.
                      </>
                    )}
                  </>
                ),
                original: (
                  <>
                    Is this the right seller? If not, select the correct one, if listed, or click the{' '}
                    <i>{CouldNotFindText}</i> link to enter their information.
                  </>
                ),
                unknown: (
                  <>
                    Search for and select your seller below. If you can&apos;t find them, click the{' '}
                    <i>{CouldNotFindText}</i> link to enter their information.
                  </>
                ),
              }[sellerType]
            }
          </Text>
        </div>

        <div className="flex column">
          <Text size="body" color="secondary" weight="bold" className="mb-4">
            Vendor Name
          </Text>

          {isUbiquiti && <SellerInfoUbiquiti />}

          {!isUbiquiti && (
            <>
              {loading && <Loading />}

              {!loading && error && (
                <Text size="body" color="danger">
                  {error}
                </Text>
              )}

              {!loading && (data?.sellers.length ? <ChooseSeller sellers={data.sellers} /> : <SearchSeller />)}
            </>
          )}

          {canSelectSeller && <Link onClick={handleSellerNotFound}>{CouldNotFindText}</Link>}
        </div>

        {isProofOfPurchaseNeeded && (
          <FileUpload
            label={isUnifiCareDistributor ? 'Device Photo' : 'Proof of Purchase'}
            required
            description={isUnifiCareDistributor ? 'Please ensure the MAC ID is visible in the photo.' : ''}
            files={proofOfPurchaseFiles}
            setFiles={setProofOfPurchaseFiles}
            maxFiles={5}
          />
        )}

        <Footer />
      </div>
    </SubmitContainer>
  )
}

function ChooseSeller({ sellers }: { sellers: CompanyInfo[] }) {
  const { seller } = useSubmitRmaStore()

  const [selectedCompanyId, selectCompanyId] = useState<CompanyId | undefined>(seller?.id)

  const [fetch, { loading }] = useRequest('companyEntry')

  const companyOptions = useMemo(() => sellers.map((entry) => ({ value: entry.id, label: entry.name })), [sellers])

  async function doFetch(companyId: CompanyId) {
    if (!selectedCompanyId) {
      return
    }

    try {
      SubmitRmaService.updateSeller(null)

      const result = await fetch({ companyId })

      SubmitRmaService.updateSeller({
        id: result.company.id,
        name: result.company.name,
        nameLegal: result.company.nameLegal || '',
        logo: result.company.logo || '',
        website: result.company.website || '',
        entityType: result.company.entityType,
      })
    } catch (err) {
      //
    }
  }

  return (
    <>
      {sellers.length > 1 && (
        <div>
          <Dropdown
            searchable
            variant="secondary"
            disabled={loading}
            width="100%"
            value={selectedCompanyId}
            options={companyOptions}
            onChange={(_, id) => {
              const newCompanyId = id as CompanyId
              const newSeller = sellers.find((entry) => entry.id === newCompanyId)
              if (!newSeller) {
                return
              }

              selectCompanyId(newSeller.id)

              void doFetch(newSeller.id)
            }}
          />
        </div>
      )}

      {loading && (
        <div className="flex justify-center align-center my-20">
          <Loader />
        </div>
      )}

      {!loading && seller && (
        <div className="mt-8 mb-4">
          <SellerInfo seller={seller} />
        </div>
      )}
    </>
  )
}

function SearchSeller() {
  const { device, seller } = useSubmitRmaStore()
  const { userCompanyId, setUserCompanyId } = useSubmitRma()

  const [getCompanyLookup, { loading, error, data }] = useCompanyLookupLazyQuery({ fetchPolicy: 'no-cache' })

  const [search, setSearch] = useState('')

  const companies = useMemo(() => {
    const companiesModified = data?.companyLookup ? [...data.companyLookup] : []

    const myCompanies = data?.myCompanies
    if (myCompanies) {
      for (let n = 0; n < myCompanies.length; n += 1) {
        const company = myCompanies[n]
        if (!companiesModified.find((entry) => entry.id === company.id)) {
          companiesModified.push(company)
        }
      }
    }

    const ubiquiti = data?.ubiquitiCompany
    if (ubiquiti && /u/i.exec(search.trim())) {
      companiesModified.push({
        addresses: [],
        id: ubiquiti?.id,
        logo: ubiquiti?.logo || '',
        name: ubiquiti?.name || '',
        nameLegal: ubiquiti?.nameLegal || '',
        website: ubiquiti?.website || '',
        entityType: CompanyType.ubiquiti,
      })
    }

    return companiesModified
  }, [data?.companyLookup, data?.myCompanies, data?.ubiquitiCompany, search])

  const isUbiquiti = device?.isSoldByUbiquiti || false
  const getCompanyLookupDebounced = useMemo(() => debounce(getCompanyLookup, 100), [getCompanyLookup])
  const fetchCompanyLookup = useCallback(
    (untrimmed: string = search) => {
      const trim = untrimmed.trim()
      if (!trim) return

      getCompanyLookupDebounced({ variables: { search: trim } })
    },
    [search, getCompanyLookupDebounced],
  )

  useEffect(() => {
    fetchCompanyLookup()
  }, [search, fetchCompanyLookup])

  if (error) {
    return <Retry text="Could not load company list." retry={fetchCompanyLookup} />
  }

  return (
    <>
      {!isUbiquiti && (
        <div>
          <Dropdown
            variant="secondary"
            searchable
            searchEmptyMessage={
              // eslint-disable-next-line no-nested-ternary
              loading ? 'Loading...' : search && !companies.length ? 'No results found.' : 'Search a seller by name.'
            }
            width="100%"
            placeholder="Search"
            value={userCompanyId}
            options={companies.map((entry) => ({ value: entry.id, label: entry.name }))}
            onChange={(_, id) => {
              const newCompany = companies.find((entry) => entry.id === id)
              if (newCompany) {
                setUserCompanyId(id as CompanyId)
                SubmitRmaService.updateSeller(sanitizeCompanyAddress(newCompany))
              }
            }}
            onInputChange={(_, value) => {
              setSearch(value as string)
            }}
            customSearchFilter={(options) => {
              // disable built in filter, show all results
              return options
            }}
          />
        </div>
      )}

      {seller && (
        <div className="mt-8 mb-4">
          <SellerInfo seller={seller} />
        </div>
      )}
    </>
  )
}

function Footer() {
  const { seller, isProofOfPurchaseNeeded } = useSubmitRmaStore()
  const { proofOfPurchaseFiles, proofOfPurchaseFilesPrev } = useSubmitRma()
  const [validateFiles, { loading, data, error }] = useValidateFilesLazy({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (data?.validateFiles) {
      SubmitRmaService.next()
    }
  }, [data])

  const isDisabled =
    !seller ||
    (isProofOfPurchaseNeeded &&
      (!proofOfPurchaseFiles.length ||
        proofOfPurchaseFiles.length > 5 ||
        !!proofOfPurchaseFiles.some((file) => !!file.error)))

  const handleNext = () => {
    try {
      useSubmitRma.setState({
        proofOfPurchaseFilesPrev: proofOfPurchaseFiles,
      })

      const files = proofOfPurchaseFiles ? proofOfPurchaseFiles.map(({ file }) => file) : []
      validateFiles({ variables: { files } })
    } catch (err) {
      //
    }
  }

  return (
    <>
      {error && proofOfPurchaseFiles === proofOfPurchaseFilesPrev && (
        <Text size="body" color="danger">
          Your file could not be validated. Please take a screenshot of the file’s information and upload it instead. If
          the issue persists, contact us at <Link href="mailto:rma@ui.com">rma@ui.com</Link> for assistance.
        </Text>
      )}

      <div className="flex justify-end gap-8">
        <Button variant="tertiary" onClick={SubmitRmaService.back}>
          Back
        </Button>

        <Button variant="primary" disabled={isDisabled} onClick={handleNext} loader={loading ? 'dots' : undefined}>
          Next
        </Button>
      </div>
    </>
  )
}
