import { TICKET_STATUS_LABELS } from '@shared/tickets'
import { ListViewIcon } from '@ubnt/icons'
import { Button, PanelCard, cssVariables } from '@ubnt/ui-components'
import type { CountryCode } from 'libphonenumber-js'
import { useMemo, useState } from 'react'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import type { TicketTabPermission } from 'rma-shared/types/permissions'
import { getFailureCategory } from 'rma-shared/types/tickets'
import styled from 'styled-components'
import { Modal } from '..'
import { useAuthStore } from '../../auth/auth-store'
import isPortal from '../../utils/isPortal'
import { formatDate } from '../../utils/time'
import { DeviceInfoPanel, sanitizeDevice } from '../Device'
import { Flex } from '../Flex'
import { ParagraphText } from '../ParagraphText'
import { TimeElapsed } from '../table-columns'
import { Text } from '../Text'
import { Attachments } from '../file-attachments'
import { Cancel } from './Cancel'
import { Dispute } from './Dispute'
import { GetSupportNotification } from './GetSupportNotification'
import { TrackingNumber } from './tracking-number'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'
import { ContactSupportAboutTicket } from './contact-support'
import { getStatusTextVariant } from './utils'
import { getTrackingNumberType } from './utils/get-tracking-number-type'

export function Details({
  ticket,
  displayCustomer = false,
  refetchTicket,
}: {
  ticket: Ticket
  displayCustomer?: boolean
  refetchTicket?: any
}) {
  const [trackingNumberType] = useState(
    getTrackingNumberType({
      country: ticket.shipmentAddress.country as CountryCode,
      status: ticket.status,
      flow: ticket.flow || 'BASIC',
      isUnifiCare: ticket.device.isUnifiCare,
      isSupportedByUI: ticket.supportByUbiquiti,
    }),
  )
  const [showTrackingModal, setShowTrackingModal] = useState(false)

  const visibleForRmaPortal = isPortal('RMA') || isPortal('FAE')

  const files = useMemo(() => {
    return [...ticket.userFiles, ...ticket.companyFiles]
  }, [ticket.userFiles, ticket.companyFiles])

  return (
    <div className="flex column pb-40" key={ticket.id}>
      <DeviceInfoPanel device={sanitizeDevice({ device: ticket.device })} colored />

      {ticket.offerUiSupport && <GetSupportNotification ticket={ticket} refetchTicket={refetchTicket} />}

      {visibleForRmaPortal && ticket.offerDispute && <DisputeClosing ticket={ticket} refetchTicket={refetchTicket} />}

      {visibleForRmaPortal && trackingNumberType && (
        <TrackingNumber ticket={ticket} trackingNumberType={trackingNumberType} />
      )}

      <PanelCard header="Overview" defaultIsOpen>
        <Overview ticket={ticket} displayCustomer={displayCustomer} />
      </PanelCard>

      <PanelCard
        header={<span data-testid="side-panel-problem-description">Problem Description</span>}
        defaultIsOpen
        style={{ marginTop: cssVariables['spacing-l'] }}
      >
        <ProblemDescription ticket={ticket} />
      </PanelCard>

      {files.length > 0 && (
        <PanelCard header="Attachments" defaultIsOpen style={{ marginTop: cssVariables['spacing-l'] }}>
          <Attachments attachments={files} />
        </PanelCard>
      )}

      {isPortal('RMA') && ticket.canCancelTicket && (
        <PanelCard header="Cancel Ticket" defaultIsOpen style={{ marginTop: cssVariables['spacing-l'] }}>
          <Cancel ticket={ticket} />
        </PanelCard>
      )}

      <Modal
        isOpen={showTrackingModal}
        size="small"
        actions={[
          {
            text: <span>Close</span>,
            variant: 'tertiary',
            onClick: () => setShowTrackingModal(false),
          },
        ]}
        title="We&rsquo;re Expediting Your Order"
      >
        <Text size="body">
          Thank you for providing your tracking number! We have expedited your replacement order and will update you on
          its status shortly.
        </Text>
      </Modal>
    </div>
  )
}

function Overview({ ticket, displayCustomer }: { ticket: Ticket; displayCustomer: boolean }) {
  const { permissions } = useAuthStore()

  return (
    <div className="flex column flex-1">
      <Block>
        {displayCustomer && (
          <div className="flex space-between split">
            <Text>Customer</Text>
            <Text truncate>{ticket.customerName}</Text>
          </div>
        )}
        <div className="flex space-between split">
          <Text>Sold by</Text>
          <Text truncate>{ticket.soldBy ?? <Text emphasis>Unidentified</Text>}</Text>
        </div>
        <div className="flex space-between split">
          <Text>Support by</Text>
          <Text truncate>{ticket.supportByName}</Text>
        </div>
        <div className="flex space-between split">
          <Text>MAC ID / Serial</Text>
          {ticket.device.mac && <Text>{formatSerial(ticket.device.mac)}</Text>}
          {!ticket.device.mac && <Text color="secondary">Not Provided</Text>}
        </div>

        {isPortal('FAE') && (
          <>
            {ticket.nsTicketId && (
              <div className="flex space-between split">
                <Text>Netsuite ID</Text>
                <Text>{ticket.nsTicketId}</Text>
              </div>
            )}

            <div className="flex space-between split">
              <Text>Netsuite Product ID</Text>
              <Text>{ticket.device.nsProductId}</Text>
            </div>

            <div className="flex space-between split">
              <Text>Original SKU</Text>
              <Text>{ticket.device.parent ? ticket.device.parent.name : ticket.device.name}</Text>
            </div>

            <div className="flex space-between split">
              <Text>Is End Customer</Text>
              <Text>{ticket.isEndCustomer ? 'Yes' : 'No'}</Text>
            </div>
          </>
        )}
      </Block>

      <Block>
        <div className="flex space-between split">
          <Text>Status</Text>
          <Text color={getStatusTextVariant(ticket.status)}>{TICKET_STATUS_LABELS[ticket.status]}</Text>
        </div>
        <div className="flex space-between split">
          <Text>Submitted On</Text>
          <Text>{formatDate(ticket.createdAt)}</Text>
        </div>

        {permissions.includes('developer') && (
          <div className="flex space-between split">
            <Text>Updated On</Text>
            <Text>{formatDate(ticket.updatedAt)}</Text>
          </div>
        )}

        <div className="flex space-between split">
          <Text>Ticket Duration</Text>
          <Text>
            <TimeElapsed from={ticket.createdAt} />
          </Text>
        </div>
      </Block>

      {ticket.shipment && (
        <Block>
          <div className="flex space-between split">
            <Text>Shipment ID</Text>
            <Text>{ticket.shipment.uid}</Text>
          </div>

          {ticket.shipment.trackingNumber && (
            <div className="flex space-between split">
              <Text>Tracking Number</Text>
              <Text truncate title={ticket.shipment.trackingNumber}>
                {ticket.shipment.trackingNumber}
              </Text>
            </div>
          )}
        </Block>
      )}

      <Block>
        {ticket.customerTrackingNumber && (
          <div className="flex space-between split">
            <Text>Customer Tracking No.</Text>
            <Text truncate>{ticket.customerTrackingNumber}</Text>
          </div>
        )}

        {ticket.replacementTrackingNumber && (
          <div className="flex space-between split">
            <Text>Replacement Tracking No.</Text>
            <Text truncate>{ticket.replacementTrackingNumber}</Text>
          </div>
        )}
      </Block>
    </div>
  )
}

const ProblemDescription = ({ ticket }: { ticket: Ticket }) => {
  return (
    <div className="flex column">
      <Block>
        <Text weight="bold">{getFailureCategory(ticket.customerFailureCategoryId)}</Text>
      </Block>
      <Block>
        <ParagraphText>{ticket.description}</ParagraphText>
      </Block>
    </div>
  )
}

export const DetailsTab = {
  id: 'details',
  path: '/details',
  icon: <ListViewIcon size="navigation" />,
  component: Details,
  permission: 'ticket-tab-details' as TicketTabPermission,
}

const DisputeClosing = ({ ticket, refetchTicket }: { ticket: Ticket; refetchTicket: () => void }) => {
  const [isModalVisible, setModalVisibility] = useState(false)

  const title = ticket.closedByUi ? 'Notification from Ubiquiti Inc.' : 'Dispute a Declined RMA Request'
  const info = ticket.closedByUi
    ? `Your ticket has been closed. Please click the link below if you haven't received your replacement item.`
    : `We see that your request was declined. If you would like to dispute the reseller's decision, contact
  us to share more details about your situation so we can recommend next steps.`

  return (
    <>
      <Dispute
        title={<>{title}</>}
        message={
          <>
            <StyledText>{info}</StyledText>

            <Button
              full={false}
              size="small"
              variant="inline"
              component={StyledLinkText}
              weight="bold"
              onClick={() => setModalVisibility(true)}
            >
              Contact Us
            </Button>
          </>
        }
      />
      {isModalVisible && (
        <ContactSupportAboutTicket
          ticketId={ticket.id}
          supportedByName={ticket.supportByName}
          onDone={() => {
            setModalVisibility(false)
            refetchTicket()
          }}
          onClose={() => setModalVisibility(false)}
          disputeType="declination"
        />
      )}
    </>
  )
}

const Block = styled(Flex).attrs({ column: true })`
  margin-top: ${cssVariables['spacing-l']};

  &:first-child {
    margin-top: 0;
  }

  &:empty {
    display: none;
  }
`

const StyledText = styled(Text)`
  margin-top: ${cssVariables['spacing-s']};
  margin-bottom: ${cssVariables['spacing-m']};
`

const StyledLinkText = styled(Text)`
  color: ${({ theme }) => theme.colors.link};
`
